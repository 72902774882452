import { Component, OnInit } from '@angular/core';
import { MillShiftService } from '../../skids/millshift.service';
import { IMillShift } from '@model/interfaces/mill-shift';
import { IExpandableObject } from '@model/expandable-object';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { DailyRunEntryDynamicControlsPartial } from '@model/partials/daily-run-entry.form-controls';
import { IDailyRunEntry } from '@model/interfaces/daily-run-entry';
import { DailyRunEntryService } from '../services/daily-run-entries.service';
import { IDailyRunSalesOrder } from '@model/interfaces/daily-run-sales-order';
import { IDailyRunLostTime } from '@model/interfaces/daily-run-lost-time';
import { DailyRunLostTimeDynamicControlsPartial } from '@model/partials/daily-run-lost-time.form-controls';
import { DailyRunSalesOrderDynamicControlsPartial } from '@model/partials/daily-run-sales-order.form-controls';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { SalesOrderService } from '../../sales-orders/sales-order.service';
import { ISalesOrder } from '@model/interfaces/sales-order';
import { OrderTypeIds } from '@model/OrderTypes';
import { DateService } from '@common/services/date.service';
import { ModalService } from '@mt-ng2/modal-module';
import { entityListModuleConfig } from '@common/shared.module';
import { ActivatedRoute } from '@angular/router';
import { AuthService, ILoggedIn } from '@mt-ng2/auth-module';
import { UserService } from '../../../app/users/user.service';

@Component({
    selector: 'app-daily-run-entries',
    styleUrls: ['./daily-run-entries.component.css'],
    templateUrl: './daily-run-entries.component.html',
})
export class DailyRunEntriesComponent implements OnInit {
    millShifts: IMillShift[] = [];
    selectedDate: string;
    selectedShift = '';

    resetAllFields = false;
    dailyRun = true;
    isAddingNewSalesOrder = false;

    dailyRunEntry: IDailyRunEntry;
    dailyRunSalesOrder: IDailyRunSalesOrder;
    dailyRunLostTime: IDailyRunLostTime;

    dailyRunForm: FormGroup;
    dailyRunEntryFormControls: IExpandableObject;
    dailyRunSalesOrdersFormControls: IExpandableObject;
    dailyRunLostTimeFormControls: IExpandableObject;
    showForm = false;
    currentPage = 1;
    dailyRunExists: boolean;

    itemsPerPage = entityListModuleConfig.itemsPerPage;
    currentUser: ILoggedIn;
    isReadOnly: boolean;

    get dailyRunEntryGroup(): FormGroup {
        return this.dailyRunForm.get('DailyRunEntry') as FormGroup;
    }

    get salesOrdersArray(): FormArray {
        return this.dailyRunForm.get('DailyRunEntry.DailyRunSalesOrders') as FormArray;
    }

    get lostTimeArray(): FormArray {
        return this.dailyRunForm.get('DailyRunEntry.DailyRunLostTimes') as FormArray;
    }

    constructor(
        private millShiftService: MillShiftService,
        private fb: FormBuilder,
        private dailyRunEntryService: DailyRunEntryService,
        private notificationsService: NotificationsService,
        private salesOrderService: SalesOrderService,
        private dateService: DateService,
        private modalService: ModalService,
        private route: ActivatedRoute,
        private authService: AuthService,
        private userService: UserService,
    ) {}

    ngOnInit(): void {
        this.millShiftService.getItems().subscribe((shifts) => {
            this.millShifts = shifts;
        });
        this.dailyRunEntryFormControls = new DailyRunEntryDynamicControlsPartial(null).Form;
        this.dailyRunSalesOrdersFormControls = new DailyRunSalesOrderDynamicControlsPartial(null).Form;
        this.dailyRunLostTimeFormControls = new DailyRunLostTimeDynamicControlsPartial().Form;

        this.dailyRunForm = this.fb.group({
            DailyRunEntry: this.fb.group({
                DailyRunLostTimes: this.fb.array([this.dailyRunLostTimeFormControls]),
                DailyRunSalesOrders: this.fb.array([this.dailyRunSalesOrdersFormControls]),
            }),
        });

        let date = this.route.snapshot.queryParamMap.get('RunDate');
        let shift = this.route.snapshot.queryParamMap.get('Shift');

        if (date && shift) {
            this.getDailyRunEntries(date, shift);
        } else {
            this.initializeDailyRun();
        }
        this.currentUser = this.authService.currentUser.getValue();
        this.userService.getIsSalesPerson(this.currentUser.Id).subscribe((isSalesPerson) => {
            this.isReadOnly = isSalesPerson;
        });

    }

    getDailyRunEntries(date?: string, shift?: string): void {
        if (date && shift) {
            this.clearForm();
            this.retrieveDailyRunEntry(date, shift);
        } else {
            if (!this.selectedDate || !this.selectedShift) {
                this.notificationsService.error('Please select a date and shift to continue');
                return;
            }
            this.retrieveDailyRunEntry(this.selectedDate, this.selectedShift);
        }
    }

    private retrieveDailyRunEntry(date: string, shift: string): void {
        const runDate = new Date(date);
        this.dailyRunEntryService.getByRunDateAndShift(runDate, shift).subscribe((dailyRunEntry: IDailyRunEntry) => {
            if (dailyRunEntry) {
                this.resetAllFields = true;
                this.initializeDailyRun(dailyRunEntry);
                this.dailyRunExists = true;
            } else {
                this.initializeDailyRun();
            }
            this.showForm = true;
            this.dailyRun = false;
        });
    }

    deleteDailyRun(): void {
        const runDate = new Date(this.selectedDate);
        const shift = this.selectedShift;

        if (isNaN(runDate.getTime()) || !shift) {
            this.notificationsService.warning('Please ensure both run date and shift are selected before proceeding.');
            return;
        }

        this.dailyRunEntryService.deleteDailyRun(runDate, shift).subscribe({
            error: () => {
                this.notificationsService.error('Failed to delete daily run');
            },
            next: () => {
                this.notificationsService.success('Daily run deleted successfully!');
                this.clearForm();
            },
        });
    }

    calculateBdlsPerPallet(salesOrderIndex: number): void {
        const salesOrder = this.salesOrdersArray.at(salesOrderIndex);
        const bundles = salesOrder.get('Bundles').value;
        const pallets = salesOrder.get('NumberOfPallets').value;
        if (bundles && pallets && pallets !== 0) {
          const bdlsPerPallet = bundles / pallets;
          salesOrder.get('BdlsPerPallet').setValue(bdlsPerPallet);
        } else {
          salesOrder.get('BdlsPerPallet').setValue('');
        }
      }

    pullSalesOrderValues(index: any): void {
        let formRow = this.salesOrdersArray.at(index);
        if (!formRow) {
            return;
        }

        let salesOrderId: number = (formRow as FormGroup).controls.SalesOrderId.value;
        if (!salesOrderId || salesOrderId === 0) {
            return;
        }

        // get the sales order
        this.salesOrderService.getById(salesOrderId).subscribe((salesOrder) => {
            if (!salesOrder) {
                this.notificationsService.error(`Sales Order ${salesOrderId} not found`);
            } else {
                this.pullSalesOrderDetailData(salesOrder, formRow);
            }
        });
    }

    pullSalesOrderDetailData(salesOrder: ISalesOrder, formRow: AbstractControl): void {
        if (!salesOrder.ManufacturingOrderDetail) {
            return;
        }

        const mfod = salesOrder.ManufacturingOrderDetail;
        let caliper = mfod.Caliper;
        let basis = mfod.Basis;
        let grade = '';
        let customerName = salesOrder.OrderInfo.Customer.CompanyName;
        let is40PoundBundle = salesOrder.OrderInfo.OrderCustomerSpecification.IsFortyPoundBundle;
        let finish = salesOrder.ManufacturingOrderDetail.Finish?.Name ?? salesOrder.ManufacturingOrderDetail.FinishCustom;
        let width = salesOrder.ManufacturingOrderDetail.Width;
        let length = salesOrder.ManufacturingOrderDetail.Length;

        if (salesOrder.TrimSheetDetails && salesOrder.TrimSheetDetails.length > 0) {

            let allOptionsMatch = salesOrder.TrimSheetDetails.length > 1 &&
            salesOrder.TrimSheetDetails.every((line) =>
                line.Width === mfod.Width &&
                line.Length === mfod.Length &&
                line.Caliper === mfod.Caliper,
            );

            if (salesOrder.TrimSheetDetails.length > 1 && !allOptionsMatch && this.isAddingNewSalesOrder) {
                let mappedOptions = {};
                salesOrder.TrimSheetDetails.forEach((line, i) => {
                    mappedOptions[i] = `Width: ${line.Width}, Length: ${line.Length}, Width: ${line.Width}, Caliper: ${line.Caliper} etc.`;
                });
                this.modalService
                    .showModal({
                        allowOutsideClick: false,
                        input: 'select',
                        inputAttributes: { required: 'true' },
                        inputOptions: mappedOptions,
                        inputPlaceholder: 'Select a run as',
                        text: `Order #${salesOrder.Id} has multiple Run As, please select one:`,
                        title: 'Select a Run As',
                        type: 'warning',
                        validationMessage: 'Please select a Run As',
                    })
                    .subscribe((result) => {
                        // result.value represents the index of the selected trimsheet detail line
                        let trimSheetDetailLine = salesOrder.TrimSheetDetails[result.value];
                        if (trimSheetDetailLine) {
                            formRow.patchValue({
                                Basis: trimSheetDetailLine.Basis,
                                Caliper: trimSheetDetailLine.Caliper,
                                Length: trimSheetDetailLine.Length,
                                Width: trimSheetDetailLine.Width,
                            });
                        }
                        this.isAddingNewSalesOrder = false;
                    });
            } else {
                let trimSheetDetailLine = salesOrder.TrimSheetDetails[0];
                basis = trimSheetDetailLine.Basis;
                caliper = trimSheetDetailLine.Caliper;
                width = trimSheetDetailLine.Width;
                length = trimSheetDetailLine.Length;
            }
        }

        if (salesOrder.OrderTypeId === OrderTypeIds.Stock) {
        } else {
            grade = salesOrder.ManufacturingOrderDetail.BoardGrade?.Name;
        }

        formRow.patchValue({
            Basis: basis,
            BoardGrade: grade,
            Caliper: caliper,
            CustomerName: customerName,
            Finish: finish,
            IsFortyPoundBundle: is40PoundBundle,
            Length: length,
            Width: width,
       });

        this.disableControlsExcept(formRow as FormGroup, ['Bundles', 'Detail', 'NumberOfPallets', 'SalesOrderId']);
    }

    disableControlsExcept(formGroup: FormGroup, enabledControls: string[]): void {
        Object.keys(formGroup.controls).forEach((controlName) => {
            if (!enabledControls.includes(controlName)) {
                formGroup.get(controlName)?.disable({ emitEvent: false });
            }
        });
    }

    isSalesOrderIdNullOrZero(index: number): boolean {
        const salesOrder = this.salesOrdersArray.at(index);
        if (!salesOrder) {
            return false;
        }
        const salesOrderId = salesOrder.get('SalesOrderId').value;
        return salesOrderId === null || salesOrderId === 0;
    }

    resetForm(): void {
        this.lostTimeArray.clear();
        this.salesOrdersArray.clear();
        this.dailyRunEntryGroup.reset();
        this.dailyRun = true;
        this.dailyRunExists = false;
    }

    onDateOrShiftChange(): void {
        this.showForm = false;
        this.resetAllFields = false;
        this.resetForm();
    }

    clearForm(): void {
        this.dailyRunSalesOrder = null;
        this.dailyRunLostTime = null;
        this.selectedDate = null;
        this.selectedShift = null;
        this.resetForm();
    }

    initializeDailyRun(dailyRunEntry?: IDailyRunEntry): void {
        this.dailyRunSalesOrder = this.dailyRunEntryService.getEmptyDailyRunSalesOrder();
        this.dailyRunLostTime = this.dailyRunEntryService.getEmptyDailyRunLostTime();
        if (dailyRunEntry) {
            this.addDailyRunSalesOrderInput(dailyRunEntry.DailyRunSalesOrders?.length);
            this.addDailyRunLostTimeInput(dailyRunEntry.DailyRunLostTimes?.length);

            const runDate = new Date(dailyRunEntry.RunDate);
            this.selectedDate = this.dateService.formatRunDate(runDate.toDateString());
            this.selectedShift = dailyRunEntry.ShiftCode;
        }
        this.dailyRunEntry = dailyRunEntry ?? this.dailyRunEntryService.getEmptydailyRunEntry();

        setTimeout(() => {
            this.dailyRunForm.patchValue({ DailyRunEntry: this.dailyRunEntry });
        });
    }

    formatDateToYYYYMMDD(date: Date): string {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    formSubmitted(): void {
        if (this.isFormEmpty()) {
            this.notificationsService.error('Failed to save the form. Please fill out the fields');
            return;
        }
        Object.assign(this.dailyRunEntry, this.dailyRunForm.getRawValue().DailyRunEntry);
        this.dailyRunEntry.RunDate = new Date(this.selectedDate);
        this.dailyRunEntry.ShiftCode = this.selectedShift;
        this.dailyRunEntryService.saveForm(this.dailyRunEntry).subscribe(() => {
            this.success();
        });
    }

    private isFormEmpty(): boolean {
        const hasRunDate = this.selectedDate && this.selectedDate.trim() !== '';
        const hasShiftCode = this.selectedShift && this.selectedShift.trim() !== '';

        return !(hasRunDate || hasShiftCode);
    }

    private success(): void {
        this.notificationsService.success('Daily Run Details saved successfully.');
    }

    removeSalesOrderInput(index: number): void {
        this.salesOrdersArray.removeAt(index);
    }

    addNewSalesOrder(): void {
        this.isAddingNewSalesOrder = true;
        this.addDailyRunSalesOrderInput();
    }

    addDailyRunSalesOrderInput(qty = 1): void {
        for (let i = 0; i < qty; i++) {
            const newSalesOrderGroup = this.fb.group(this.dailyRunSalesOrder);
            this.salesOrdersArray.push(newSalesOrderGroup);
        }
    }

    removeLostTimeInput(index: number): void {
        this.lostTimeArray.removeAt(index);
    }

    addDailyRunLostTimeInput(qty = 1): void {
        for (let i = 0; i < qty; i++) {
            const newLostTimeGroup = this.fb.group(this.dailyRunLostTime);
            this.lostTimeArray.push(newLostTimeGroup);
        }
    }

    cancel(): void {
        this.clearForm();
    }
}
