import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';
import { IMetaItem } from '../interfaces/base';

import { IExpandableObject } from '../expandable-object';
import { IDailyRunSalesOrder } from '../interfaces/daily-run-sales-order';
import { IDailyRunEntry } from '../interfaces/daily-run-entry';
import { ISalesOrder } from '../interfaces/sales-order';

export interface IDailyRunSalesOrderDynamicControlsParameters {
    formGroup?: string;
    dailyRunEntries?: IDailyRunEntry[];
    salesOrders?: ISalesOrder[];
}

export class DailyRunSalesOrderDynamicControls {

    formGroup: string;
    dailyRunEntries?: IDailyRunEntry[];
    salesOrders?: ISalesOrder[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private dailyrunsalesorder?: IDailyRunSalesOrder, additionalParameters?: IDailyRunSalesOrderDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'DailyRunSalesOrder';
        this.dailyRunEntries = additionalParameters && additionalParameters.dailyRunEntries || undefined;
        this.salesOrders = additionalParameters && additionalParameters.salesOrders || undefined;

        this.Form = {
            Basis: new DynamicField({
                formGroup: this.formGroup,
                label: 'Basis',
                name: 'Basis',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 1,
                }),
                validation: [  ],
                validators: {  },
                value: this.dailyrunsalesorder && this.dailyrunsalesorder.Basis || null,
            }),
            BdlsPerPallet: new DynamicField({
                formGroup: this.formGroup,
                label: 'Bdls Per Pallet',
                name: 'BdlsPerPallet',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.dailyrunsalesorder && this.dailyrunsalesorder.BdlsPerPallet || null,
            }),
            BoardGrade: new DynamicField({
                formGroup: this.formGroup,
                label: 'Board Grade',
                name: 'BoardGrade',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.dailyrunsalesorder && this.dailyrunsalesorder.hasOwnProperty('BoardGrade') && this.dailyrunsalesorder.BoardGrade != null ? this.dailyrunsalesorder.BoardGrade.toString() : '',
            }),
            Bundles: new DynamicField({
                formGroup: this.formGroup,
                label: 'Bundles',
                name: 'Bundles',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.dailyrunsalesorder && this.dailyrunsalesorder.Bundles || null,
            }),
            Caliper: new DynamicField({
                formGroup: this.formGroup,
                label: 'Caliper',
                name: 'Caliper',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 3,
                }),
                validation: [  ],
                validators: {  },
                value: this.dailyrunsalesorder && this.dailyrunsalesorder.Caliper || null,
            }),
            CustomerName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Customer Name',
                name: 'CustomerName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.dailyrunsalesorder && this.dailyrunsalesorder.hasOwnProperty('CustomerName') && this.dailyrunsalesorder.CustomerName != null ? this.dailyrunsalesorder.CustomerName.toString() : '',
            }),
            DailyRunEntryId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Daily Run Entry',
                name: 'DailyRunEntryId',
                options: this.dailyRunEntries,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.dailyrunsalesorder && this.dailyrunsalesorder.DailyRunEntryId || null,
            }),
            Detail: new DynamicField({
                formGroup: this.formGroup,
                label: 'Detail',
                name: 'Detail',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.dailyrunsalesorder && this.dailyrunsalesorder.hasOwnProperty('Detail') && this.dailyrunsalesorder.Detail != null ? this.dailyrunsalesorder.Detail.toString() : '',
            }),
            Finish: new DynamicField({
                formGroup: this.formGroup,
                label: 'Finish',
                name: 'Finish',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.dailyrunsalesorder && this.dailyrunsalesorder.hasOwnProperty('Finish') && this.dailyrunsalesorder.Finish != null ? this.dailyrunsalesorder.Finish.toString() : '',
            }),
            IsFortyPoundBundle: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Forty Pound Bundle',
                name: 'IsFortyPoundBundle',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.dailyrunsalesorder && this.dailyrunsalesorder.hasOwnProperty('IsFortyPoundBundle') && this.dailyrunsalesorder.IsFortyPoundBundle != null ? this.dailyrunsalesorder.IsFortyPoundBundle : false,
            }),
            IsRunSheetStock: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Run Sheet Stock',
                name: 'IsRunSheetStock',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.dailyrunsalesorder && this.dailyrunsalesorder.hasOwnProperty('IsRunSheetStock') && this.dailyrunsalesorder.IsRunSheetStock != null ? this.dailyrunsalesorder.IsRunSheetStock : false,
            }),
            Length: new DynamicField({
                formGroup: this.formGroup,
                label: 'Length',
                name: 'Length',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.dailyrunsalesorder && this.dailyrunsalesorder.Length || null,
            }),
            NumberOfPallets: new DynamicField({
                formGroup: this.formGroup,
                label: 'Number Of Pallets',
                name: 'NumberOfPallets',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.dailyrunsalesorder && this.dailyrunsalesorder.NumberOfPallets || 0,
            }),
            SalesOrderId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Sales Order',
                name: 'SalesOrderId',
                options: this.salesOrders,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.dailyrunsalesorder && this.dailyrunsalesorder.SalesOrderId || 0,
            }),
            Width: new DynamicField({
                formGroup: this.formGroup,
                label: 'Width',
                name: 'Width',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.dailyrunsalesorder && this.dailyrunsalesorder.Width || null,
            }),
        };

        this.View = {
            Basis: new DynamicLabel({
                label: 'Basis',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 1,
                }),
                value: this.dailyrunsalesorder && this.dailyrunsalesorder.Basis || null,
            }),
            BdlsPerPallet: new DynamicLabel({
                label: 'Bdls Per Pallet',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                value: this.dailyrunsalesorder && this.dailyrunsalesorder.BdlsPerPallet || null,
            }),
            BoardGrade: new DynamicLabel({
                label: 'Board Grade',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                value: this.dailyrunsalesorder && this.dailyrunsalesorder.hasOwnProperty('BoardGrade') && this.dailyrunsalesorder.BoardGrade != null ? this.dailyrunsalesorder.BoardGrade.toString() : '',
            }),
            Bundles: new DynamicLabel({
                label: 'Bundles',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                value: this.dailyrunsalesorder && this.dailyrunsalesorder.Bundles || null,
            }),
            Caliper: new DynamicLabel({
                label: 'Caliper',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 3,
                }),
                value: this.dailyrunsalesorder && this.dailyrunsalesorder.Caliper || null,
            }),
            CustomerName: new DynamicLabel({
                label: 'Customer Name',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                value: this.dailyrunsalesorder && this.dailyrunsalesorder.hasOwnProperty('CustomerName') && this.dailyrunsalesorder.CustomerName != null ? this.dailyrunsalesorder.CustomerName.toString() : '',
            }),
            DailyRunEntryId: new DynamicLabel({
                label: 'Daily Run Entry',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                value: getMetaItemValue(this.dailyRunEntries as unknown as IMetaItem[], this.dailyrunsalesorder && this.dailyrunsalesorder.hasOwnProperty('DailyRunEntryId') ? this.dailyrunsalesorder.DailyRunEntryId : null) ?? '',
            }),
            Detail: new DynamicLabel({
                label: 'Detail',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                value: this.dailyrunsalesorder && this.dailyrunsalesorder.hasOwnProperty('Detail') && this.dailyrunsalesorder.Detail != null ? this.dailyrunsalesorder.Detail.toString() : '',
            }),
            Finish: new DynamicLabel({
                label: 'Finish',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                value: this.dailyrunsalesorder && this.dailyrunsalesorder.hasOwnProperty('Finish') && this.dailyrunsalesorder.Finish != null ? this.dailyrunsalesorder.Finish.toString() : '',
            }),
            IsFortyPoundBundle: new DynamicLabel({
                label: 'Is Forty Pound Bundle',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                value: this.dailyrunsalesorder && this.dailyrunsalesorder.hasOwnProperty('IsFortyPoundBundle') && this.dailyrunsalesorder.IsFortyPoundBundle != null ? this.dailyrunsalesorder.IsFortyPoundBundle : false,
            }),
            IsRunSheetStock: new DynamicLabel({
                label: 'Is Run Sheet Stock',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                value: this.dailyrunsalesorder && this.dailyrunsalesorder.hasOwnProperty('IsRunSheetStock') && this.dailyrunsalesorder.IsRunSheetStock != null ? this.dailyrunsalesorder.IsRunSheetStock : false,
            }),
            Length: new DynamicLabel({
                label: 'Length',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                value: this.dailyrunsalesorder && this.dailyrunsalesorder.hasOwnProperty('Length') && this.dailyrunsalesorder.Length != null ? this.dailyrunsalesorder.Length.toString() : '',
            }),
            NumberOfPallets: new DynamicLabel({
                label: 'Number Of Pallets',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                value: this.dailyrunsalesorder && this.dailyrunsalesorder.NumberOfPallets || 0,
            }),
            SalesOrderId: new DynamicLabel({
                label: 'Sales Order',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                value: getMetaItemValue(this.salesOrders as unknown as IMetaItem[], this.dailyrunsalesorder && this.dailyrunsalesorder.hasOwnProperty('SalesOrderId') ? this.dailyrunsalesorder.SalesOrderId : null) ?? '',
            }),
            Width: new DynamicLabel({
                label: 'Width',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                value: this.dailyrunsalesorder && this.dailyrunsalesorder.hasOwnProperty('Width') && this.dailyrunsalesorder.Width != null ? this.dailyrunsalesorder.Width.toString() : '',
            }),
        };

    }
}
