<div>
    <div class="row">
        <div class="col-md-7">
            <h2>Production Data for Order #{{ salesOrderId }}</h2>
        </div>
    </div>
    <div class="row" *ngIf="productionData?.DailyRunSalesOrders.length || productionData?.ConvertingData.length">
        <div class="col m-x-lg custom-button-container">
            <button class="btn btn-secondary btn-primary-inverse" (click)="print()" mt-doubleClickDisabled [(doubleClickIsDisabled)]="doubleClickIsDisabled">
                <i class="fa fa-fw fa-print"></i> Print
            </button>
        </div>
    </div>
</div>
<div class="p-sm">
    <div class="row m-t-md" *ngIf="productionData?.DailyRunSalesOrders && productionData?.DailyRunSalesOrders.length > 0">
        <div class="col-md-12">
            <table class="table table-striped cursor-auto">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Shift</th>
                        <th>Third Hand</th>
                        <th>Backtender</th>
                        <th>Tour Boss</th>
                        <th>Finishers</th>
                        <th>Banders</th>
                        <th>Bundles</th>
                        <th># of Pallets</th>
                        <th>Bdls/Pallet</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let salesOrder of productionData?.DailyRunSalesOrders">
                            <tr (click)="onDailyRunSelected(salesOrder)">
                                <td>{{ salesOrder.DailyRunEntry.RunDate | date : 'MM/dd/yyyy' }}</td>
                                <td>{{ salesOrder.DailyRunEntry.ShiftCode }}</td>
                                <td>{{ salesOrder.DailyRunEntry.ThirdHandValues | uppercase }}</td>
                                <td>{{ salesOrder.DailyRunEntry.Backtender | uppercase }}</td>
                                <td>{{ salesOrder.DailyRunEntry.TourBoss | uppercase }}</td>
                                <td>{{ salesOrder.DailyRunEntry.Finishers | uppercase }}</td>
                                <td>{{ salesOrder.DailyRunEntry.Banders | uppercase }}</td>
                                <td>{{ salesOrder.Bundles }}</td>
                                <td>{{ salesOrder.NumberOfPallets }}</td>
                                <td>{{ salesOrder.BdlsPerPallet }}</td>
                            </tr>
                    </ng-container>
                </tbody>
            </table>
            <hr class="m-y-lg" />
        </div>
    </div>

    <div class="row m-t-sm" *ngIf="productionData?.ConvertingData && productionData?.ConvertingData.length > 0">
        <div class="col-md-7">
            <h3>Converting Data</h3>
        </div>
        <div class="col-md-12">
            <table class="table table-striped cursor-auto">
                <thead>
                    <tr>
                        <th>Type</th>
                        <th># of Bundles</th>
                        <th># of Skids</th>
                        <th>Date</th>
                        <th>Start Time</th>
                        <th>End Time</th>
                        <th>Users Entered</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let detail of productionData?.ConvertingData">
                        <td>{{ detail.ConvertingDataType }}</td>
                        <td>{{ detail.Bundles }}</td>
                        <td>{{ detail.Skids }}</td>
                        <td>{{ detail.Date | date : 'MM/dd/yyyy' }}</td>
                        <td>{{ detail.StartTime != '00:00:00' ? detail.StartTime : '' }}</td>
                        <td>{{ detail.FinishTime != '00:00:00' ? detail.FinishTime : '' }}</td>
                        <td>{{ detail.UserEntered | uppercase }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="centered-container" *ngIf="!productionData?.DailyRunSalesOrders.length && !productionData?.ConvertingData.length">
        <i class="font-italic">No production data available.</i>
    </div>
</div>
