<h2>Load Fulfillment</h2>

<table class="table table-hover m-b-0">
    <thead>
        <tr class="no-pointer">
            <th>Load ID</th>
            <th>Trailer</th>
            <th>Status</th>
            <th>Light Weight</th>
            <th>Target Load Weight</th>
            <th>Current Load</th>
            <th>Weight Available to Load</th>
            <th class="text-center">Skids Loaded</th>
            <th class="text-center">Actions</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let shipment of openShipments" (click)="shipmentSelected(shipment.ShipmentId)">
            <td>{{ shipment.ShipmentId }}</td>
            <td>{{ shipment.Trailer?.Number }}</td>
            <td>{{ shipment.IsPickup ? 'PICKUP' : 'SETUP' }}</td>
            <td>{{ shipment.LightWeight }}</td>
            <td>{{ shipment.TargetWeight }}</td>
            <td>{{ shipment.CurrentWeight }}</td>
            <td>{{ shipment.TargetWeight - shipment.CurrentWeight }}</td>
            <td class="text-center">{{ shipment.SkidsLoaded + ' / ' + shipment.NumberOfSkids }}</td>
            <td class="text-center">
                <i class="fa fa-fw fa-lg fa-cog pointer" (click)="openContextMenu($event, shipment)"></i>
            </td>
        </tr>
        <tr *ngIf="!openShipments || !openShipments.length">
            <td colspan="10">
                <h3 class="text-center">No data to display.</h3>
            </td>
        </tr>
    </tbody>
</table>

<ngb-pagination
    [collectionSize]="total"
    [pageSize]="pageSize"
    [(page)]="currentPage"
    (pageChange)="bindGrid()"
    *ngIf="openShipments && openShipments.length"
    maxSize="7"
    rotate="true"
></ngb-pagination>

<div>
    <fieldset>
        <legend>Truck Loads</legend>
        <button type="button" class="btn btn-default" (click)="showOpenLoadDialog()">Open Load</button>
    </fieldset>
    <fieldset>
        <legend>Print Report</legend>
        <button class="btn btn-default" type="button" (click)="showLoadNumberDialog()">Choose Report</button>
    </fieldset>
</div>

<context-menu #shipmentContextMenu>
    <ng-template contextMenuItem (execute)="showTrailersDialog($event.item)"> Assign Trailer </ng-template>
    <ng-template contextMenuItem (execute)="allocateToDloc($event.item)">Allocation </ng-template>
    <ng-template contextMenuItem (execute)="quickLoad($event.item)"> Quick Load </ng-template>
    <ng-template contextMenuItem (execute)="showReassignSkidDialog($event.item)"> Reassign Skid </ng-template>
    <ng-template contextMenuItem (execute)="showLoadSkidDialog($event.item)"> Ship Skid </ng-template>
    <ng-template contextMenuItem (execute)="printLoad($event.item)" [enabled]="isPrintEnabled"> Print Load </ng-template>
    <ng-template contextMenuItem divider="true"></ng-template>
    <ng-template contextMenuItem (execute)="closeLoad($event.item)"> Close Load </ng-template>
</context-menu>

<modal-wrapper [options]="openLoadModalOptions" title="Open Load" [autoShow]="false" (ready)="openLoadModal = $event">
    <open-existing-shipment (onShipmentOpened)="bindGrid()" (onCancel)="closeOpenLoadDialog()"></open-existing-shipment>
</modal-wrapper>

<modal-wrapper [options]="loadSkidModalOptions" title="Ship Skid" [autoShow]="false" (ready)="loadSkidModal = $event">
    <load-skid [shipmentId]="selectedShipmentId" (onSkidLoaded)="bindGrid()" (onCancel)="closeLoadSkidDialog()"></load-skid>
</modal-wrapper>

<modal-wrapper [options]="reassignSkidModalOptions" title="Reassign Skid" [autoShow]="false" (ready)="reassignSkidModal = $event">
    <reassign-skid [salesOrderIds]="salesOrderIds" [shipmentId]="selectedShipmentId" (onSkidReassigned)="shipSkid($event)" (onCancel)="closeReassignSkidDialog()"></reassign-skid>
</modal-wrapper>

<modal-wrapper [options]="shipmentDetailModalOptions" title="Load Details" [autoShow]="false" (ready)="shipmentDetailModal = $event">
    <shipment-skid [shipment]="selectedShipment" [canRemoveSkids]="true" (onSkidRemoved)="bindGrid()"></shipment-skid>
</modal-wrapper>

<modal-wrapper [options]="assignTrailerModalOptions" title="Assign Trailer" [autoShow]="false" (ready)="assignTrailerModal = $event">
    <assign-trailer
        [shipmentId]="selectedOpenShipment?.ShipmentId"
        [trailerId]="selectedOpenShipment?.Trailer?.Id"
        [haulerId]="selectedOpenShipment?.Hauler?.Id"
        [assign]="assignTrailer.bind(this)"
    ></assign-trailer>
</modal-wrapper>
